import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    users: [],
    loading: false,
    addSuccess: false,
    page: 1,
    selectedValue: 10,
    addLoading: false,
    editLoading: false,
    EditSuccess: false,
    chargerDisplayValues: [],
    chargerCurrentRules: [],
    StateDisplayValues: [],
    StateCurrentRules: [],
    CityDisplayValues: [],
    CityCurrentRules: [],
    currentRules: [],
    displayValues: [],
    TechSupportFormValue: {
        cityId: [],
        stateId: [],
        chargerIds: [],
        statusId: [],
        age_op: "",
        age: [],
        more_than_age: "",
        between_age1: "",
        between_age2: "",
        less_than_age: "",
        assigned_to: {
            id: "",
            title: null,
        },
        TicketStartDate: "",
        TicketEndDate: "",
        ETDStartDate: "",
        ETDEndDate: "",
        ClosureStartDate: "",
        ClosureEndDate: "",
        TaxationStartDate: "",
        TaxationEndDate: "",
        search_key: "",
        sow: "",
        parent_status: "all_tab",
    },
    TechSupportDealTab: 0,
    selectedTab: 0,
}

const TechSupportSlice = createSlice({
    name: "TechSupport",
    initialState,
    reducers: {
        setTechSupportForm: (state, action) => {
            state.TechSupportFormValue = action?.payload
        },
        setDisplayValues: (state, action) => {
            state.displayValues = action?.payload
        },
        setCurrentRuleValue: (state, action) => {
            state.currentRules = action?.payload
        },
        setChargerDisplayValues: (state, action) => {
            state.chargerDisplayValues = action?.payload
        },
        setChargerCurrentRuleValue: (state, action) => {
            state.chargerCurrentRules = action?.payload
        },
        setCityDisplayValues: (state, action) => {
            state.CityDisplayValues = action?.payload
        },
        setCityCurrentRuleValue: (state, action) => {
            state.CityCurrentRules = action?.payload
        },
        setStateDisplayValues: (state, action) => {
            state.StateDisplayValues = action?.payload
        },
        setStateCurrentRuleValue: (state, action) => {
            state.StateCurrentRules = action?.payload
        },
        setPages: (state, action) => {
            state.page = action?.payload
        },
        setSelectedValues: (state, action) => {
            state.selectedValue = action?.payload
        },
        setDealTab: (state, action) => {
            state.TechSupportDealTab = action?.payload
        },
        setSelectedTab: (state, action) => {
            state.selectedTab = action?.payload
        },
    },
})

export const {
    setDisplayValues,
    setChargerCurrentRuleValue,
    setChargerDisplayValues,
    setCurrentRuleValue,
    setTechSupportForm,
    setCityCurrentRuleValue,
    setCityDisplayValues,
    setStateCurrentRuleValue,
    setStateDisplayValues,
    setPages,
    setSelectedValues,
    setSelectedTab,
    setDealTab,
} = TechSupportSlice.actions

export default TechSupportSlice.reducer
